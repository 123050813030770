import React, { useState } from 'react';
import { Layout } from '../components/Layout';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { AlphaBroderLogoCycler } from '@components/AlphaBroderLogoCycler';
import { SanmarLogoCycler } from '@components/SanmarLogoCycler';
import { Button } from '@components/Button';
import { Icon } from '@svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

interface BrowseIconProps {
  isHover?: boolean;
}

const Catalogs = () => {
  const data = useStaticQuery(graphql`
    {
      alphabroder: file(relativePath: { eq: "catalogs/alphabroder-logo.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sanmar: file(relativePath: { eq: "catalogs/sanmar-logo.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const buttonAnimationState = {
    y: -2,
    boxShadow: '0px 13px 27px rgba(50, 50, 93, 0.25)',
    transition: {
      type: 'spring',
    },
  };

  const [isAlphaButtonHover, setIsAlphaButtonHover] = useState(false);
  const [isSanmarButtonHover, setIsSanmarButtonHover] = useState(false);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="catalogs">
        <Container>
          <AlphaBroderImage fluid={data.alphabroder.childImageSharp.fluid} />
          <AlphaBroderLogoCycler />
          <A
            onHoverStart={() => setIsAlphaButtonHover(true)}
            onHoverEnd={() => setIsAlphaButtonHover(false)}
            whileHover={buttonAnimationState}
            href="https://alphabroder.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AlphaButton
              red
              icon={
                <BrowseIcon
                  scale={1.5}
                  name="browse"
                  isHover={isAlphaButtonHover}
                  className="alpha"
                />
              }
            >
              browse alphabroder attire
            </AlphaButton>
          </A>
          <SanmarImage fluid={data.sanmar.childImageSharp.fluid} />
          <SanmarLogoCycler />
          <A
            onHoverStart={() => setIsSanmarButtonHover(true)}
            onHoverEnd={() => setIsSanmarButtonHover(false)}
            whileHover={buttonAnimationState}
            href="https://sanmar.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SanmarButton
              red
              icon={
                <BrowseIcon
                  scale={1.5}
                  name="browse"
                  className="sanmar"
                  isHover={isSanmarButtonHover}
                />
              }
            >
              browse sanmar attire
            </SanmarButton>
          </A>
          <Space />
        </Container>
      </Layout>
    </motion.div>
  );
};

export default Catalogs;

const Container = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
`;

const AlphaBroderImage = styled(Img)<GatsbyImage>`
  width: 700px;
  margin-bottom: 50px;
  justify-self: center;

  @media (max-width: 740px) {
    width: 500px;
  }

  @media (max-width: 540px) {
    width: 400px;
  }

  @media (max-width: 440px) {
    width: 300px;
  }
`;

const SanmarImage = styled(AlphaBroderImage)`
  margin: 200px 0 75px;
`;

const Space = styled.div`
  margin-bottom: 200px;
`;

const A = styled(motion.a)`
  cursor: pointer;
  width: auto;
  text-decoration: none;
  justify-self: center;
  margin-top: 50px;
`;

const BrowseIcon = styled(Icon)<BrowseIconProps>`
  margin-left: 1em;
  /* transition: all 0.2s ease; */
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.35));
`;

const ModifiedButton = styled(Button)`
  font-size: 32px;
  padding: 20px 35px;
  transition: all 0.1s ease;
  border-radius: 4px;
`;

const SanmarButton = styled(ModifiedButton)`
  @media (max-width: 785px) {
    margin-top: 65px;
  }

  @media (max-width: 460px) {
    font-size: 28px;
  }

  @media (max-width: 421px) {
    font-size: 18px;
  }
`;

const AlphaButton = styled(ModifiedButton)`
  @media (max-width: 715px) {
    margin-top: 50px;
  }

  @media (max-width: 460px) {
    font-size: 28px;
  }

  @media (max-width: 421px) {
    font-size: 18px;
  }
`;
